// Generated by Framer (f92ac27)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {y4SuaG8Gu: {hover: true}};

const cycleOrder = ["y4SuaG8Gu"];

const variantClassNames = {y4SuaG8Gu: "framer-v-1oxz3n7"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};


function toResponsiveImage_194x2gw(value) {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

function useRandomID(){const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; link?: string; image?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "y4SuaG8Gu", link: e1gAYkMtf, image: y9gDI7M7X = {src: new URL("assets/PxDwqiPRhx1cxX92UBMcLQQxj4.png", import.meta.url).href}, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "y4SuaG8Gu", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-1Y5TF", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? "auto"}}>
<Link href={e1gAYkMtf}><Image {...restProps} as={"a"} background={{alt: "", fit: "fill", intrinsicHeight: 60, intrinsicWidth: 60, pixelHeight: 60, pixelWidth: 60, ...toResponsiveImage_194x2gw(y9gDI7M7X)}} className={`${cx("framer-1oxz3n7", className)} framer-1k66bh9`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"y4SuaG8Gu"} ref={ref} style={{filter: "grayscale(1)", WebkitFilter: "grayscale(1)", ...style}} transition={transition} variants={{"y4SuaG8Gu-hover": {filter: "grayscale(0)", WebkitFilter: "grayscale(0)"}}} {...addPropertyOverrides({"y4SuaG8Gu-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}/></Link>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-1Y5TF [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-1Y5TF * { box-sizing: border-box; }", ".framer-1Y5TF .framer-1k66bh9 { display: block; }", ".framer-1Y5TF .framer-1oxz3n7 { height: 32px; overflow: hidden; position: relative; text-decoration: none; width: 32px; }", ".framer-1Y5TF .framer-v-1oxz3n7 .framer-1oxz3n7 { cursor: pointer; }", ".framer-1Y5TF.framer-v-1oxz3n7.hover .framer-1oxz3n7 { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 32px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 32
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"FVtv7lC8z":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"e1gAYkMtf":"link","y9gDI7M7X":"image"}
 */
const FramerxsGr2rB9S: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerxsGr2rB9S;

FramerxsGr2rB9S.displayName = "follow-sona-on-twitter";

FramerxsGr2rB9S.defaultProps = {height: 32, width: 32};

addPropertyControls(FramerxsGr2rB9S, {e1gAYkMtf: {title: "Link", type: ControlType.Link}, y9gDI7M7X: {__defaultAssetReference: "data:framer/asset-reference,PxDwqiPRhx1cxX92UBMcLQQxj4.png?originalFilename=image.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerxsGr2rB9S, [])